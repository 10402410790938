import React, {Component}                                from 'react';
import { KirokuButton, KirokuLoading, KirokuUploadFile, KirokuButtonLink } from '../../../../ui';
import {uploadService}                                   from '../../../../services';
import {withNamespaces}                                  from 'react-i18next';
import {conditionUpload}                      from '../../../../services/FitlerData';
import ModalNotificationValidateUpload        from '../../QuestionTypeWeb/ModalNotificationValidateUpload';
import {connect}                              from 'react-redux';
import {turnOnNotificationValidatedMediaFile} from '../../../../actions/TakeSurvey';
import {checkUseHeadingQuestion}              from "../../../Surveys/SurveyEditor/SurveyService";
import { withSmoothScrolling }                from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
@connect(null, dispatch => {
    return {
        turnOnNotificationValidatedMediaFile: () => {
            dispatch(turnOnNotificationValidatedMediaFile())
        }
    }
})
class MediaFileQuestion extends Component {

    state = {
        imageUrl: this.props.value.answer ? this.props.value.answer : null,
        typeAnswer: this.props.value.typeAnswer ? this.props.value.typeAnswer : null,
        loadingImage: false,
        openNotification: false,
    };

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                imageUrl: nextProps.value.answer,
                typeAnswer: nextProps.value.typeAnswer ? nextProps.value.typeAnswer : null
            });

        }
    }


    handleChangePhoto(e) {

        if (e.currentTarget.files[0]) {
            if (conditionUpload(e.currentTarget.files[0])) {
                const {question} = this.props;
                const nextQuestion = question.nextQuestion || question.index + 1;
                this.setState({
                    nextQuestion: nextQuestion,
                    typeAnswer: e.currentTarget.files[0].type,
                    loadingImage: true
                });
                let formData = new FormData();
                formData.append('file', e.currentTarget.files[0]);
                formData.append('name', e.currentTarget.files[0].name);
                uploadService.uploadImage(formData, 'avatar').then(res => {
                    this.setState({
                        imageUrl: res.url,
                        loadingImage: false,
                    });
                    const answer = {
                        answer: res.url, typeAnswer: this.state.typeAnswer
                    };
                    this.props.onAnswer({
                        answer: answer,
                        validated: answer ? answer : null,
                        questionIndex: this.props.question.index
                    });
                }).catch(e => {
                    console.log(e);
                });
                this.props.isCurrentQuestion(this.props.question.index)
            } else {
                this.props.turnOnNotificationValidatedMediaFile()
            }


        }
    }

    handleResetAnswer() {
        this.setState({
            validated   : false,
            imageUrl    : '',
        });

        this.props.onAnswer({
            answer   : {},
            validated: null,
            questionIndex: this.props.question.index
        });

        this.props.isCurrentQuestion(this.props.question.index);

    }

    moveToNextQuestion() {
        const  {question} = this.props;
        let nextToQuestion;
        nextToQuestion = question.nextQuestion ? question.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${nextToQuestion }`);
        this.props.isCurrentQuestion(nextToQuestion + 1);
    }

    render() {
        const {question, disabled,
                  t, isEndQuestion, currentQuestion} = this.props;
        const {loadingImage, imageUrl}   = this.state;
        const contentQuestion = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>

                <ModalNotificationValidateUpload/>

                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className={disabled ? 'number-question bg-disable-question' : 'number-question d-flex justify-content-between'}>
                            <div className="question-number text-overflow-elip">
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div/>
                            <div hidden={disabled}>
                                <KirokuButtonLink
                                    className='padding-reset-button-link'
                                    onClick={() => {this.handleResetAnswer()}}>
                                    {t('createRecord.resetAnswer')}
                                </KirokuButtonLink>
                            </div>
                        </div>
                        <span className='content-name-question' style={{opacity: disabled ? 0.3 : 1}}>
                            {question.content}
                        </span>
                        <div className='answer-question pb-4' style={{opacity: disabled ? 0.3 : 1}}>
                            {loadingImage ? <KirokuLoading/>
                                : <KirokuUploadFile url={imageUrl} disabled={disabled}
                                                    onChange={this.handleChangePhoto.bind(this)}/>
                            }
                        </div>
                        <div className='p-2' hidden={ isEndQuestion|| disabled || question.index !== currentQuestion}
                             style={{ textAlign : 'center' }}>
                            <KirokuButton onClick={this.moveToNextQuestion.bind(this)} className='btn-kiroku-primary'>
                                {this.props.t('common.nextToQuestion')}
                            </KirokuButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MediaFileQuestion;
