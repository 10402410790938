import React, {Component}               from 'react';
import { KirokuButton, KirokuCheckBox, KirokuButtonLink } from '../../../../ui';
import {withNamespaces}                 from 'react-i18next';
import {checkUseHeadingQuestion}        from "../../../Surveys/SurveyEditor/SurveyService";
import { withSmoothScrolling }          from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
class MultiChoice extends Component {

    state = {
        answers: [...this.props.value],
    };

    componentDidMount() {
        const {value}   = this.props;
        this.setState({
            answers: [...value]
        });

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.question.index !== nextProps.question.index || this.props.value !== nextProps.value) {
            const {value}   = nextProps;
            this.setState({
                answers: value
            });
        }

    }

    handleOnChange(index, e) {
        const { value } = this.props;
        const newAnswer  = [...this.state.answers];
        newAnswer[index] = {...newAnswer[index], is_choice: e.currentTarget.checked};
        this.setState({answers: newAnswer });
        const notAnswer = newAnswer.filter(answer => !answer['is_choice']).length === value.length;
        this.props.onAnswer({
            answer       : newAnswer,
            validated    : !notAnswer ? newAnswer : null,
            questionIndex: this.props.question.index,
        });
        this.props.isCurrentQuestion(this.props.question.index);
    }

    handleResetAnswer() {
        let newAnswer  = [...this.state.answers];
        newAnswer = newAnswer.map((answer) => {
            return {...answer, is_choice: false}
        });
        this.setState({answers: newAnswer, validated: false});
        this.props.onAnswer({
            answer       : newAnswer,
            validated    : null,
            questionIndex: this.props.question.index,
        });
        this.props.isCurrentQuestion(this.props.question.index)
    }


    moveToNextQuestion(){
        const {question}    = this.props;
        let nextToQuestion = question.nextQuestion ? question.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${nextToQuestion }`);
        this.props.isCurrentQuestion(nextToQuestion + 1);

    }

    render() {
        const { question, disabled,
                  t, isEndQuestion, currentQuestion } = this.props;
        const { answers }                 = this.state;
        const contentQuestion = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>
                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className={disabled ? 'number-question bg-disable-question' : 'number-question d-flex justify-content-between'}>
                            <div className="question-number text-overflow-elip">
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div/>
                            <div hidden={disabled}>
                                <KirokuButtonLink
                                    className='padding-reset-button-link'
                                    onClick={() => {this.handleResetAnswer()}}>
                                    {t('createRecord.resetAnswer')}
                                </KirokuButtonLink>
                            </div>
                        </div>
                        <span className='content-name-question' style={{opacity: disabled ? 0.3 : 1}}>
                            {question.content}
                        </span>
                        <div className={`answer-question ${ answers.length > 10 ? 'scrollbar' : '' }`}  id={"style-scroll"} style={{opacity: disabled ? 0.3 : 1}}>
                            <div className={`${ answers.length > 10 ? 'force-overflow' : '' }`}>
                                {answers.map((item, index) =>
                                    <KirokuCheckBox key={index}
                                                    disabled={disabled}
                                                    checked={item.is_choice}
                                                    id={`${question.index}-${index}`}
                                                    content={item.answer}
                                                    onChange={this.handleOnChange.bind(this, index)}
                                    />
                                )}
                            </div>
                            <div className='p-2' hidden={ isEndQuestion|| disabled || question.index !== currentQuestion}>
                                <KirokuButton onClick={this.moveToNextQuestion.bind(this)} className='btn-kiroku-primary'>
                                    {this.props.t('common.nextToQuestion')}
                                </KirokuButton>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default MultiChoice;
