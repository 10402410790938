import React, { Component }              from 'react';
import moment                            from 'moment';
import { KirokuButton, KirokuInputIcon, KirokuButtonLink } from '../../../../ui';
import { withNamespaces }                from 'react-i18next';
import { checkUseHeadingQuestion }       from '../../../Surveys/SurveyEditor/SurveyService';
import { withSmoothScrolling }           from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
class TimeQuestion extends Component {

    state = {
        value      : null,
        clock      : '',
        validated : null,
    };

    componentDidMount() {

    }

    componentWillMount() {
        if (this.props.value) {
            this.setState({
                value: this.props.value,
                clock: moment.unix(this.props.value).format('HH:mm')
            })
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value || this.props.question.index !== nextProps.question.index) {
            this.setState({
                value: nextProps.value,
                clock: nextProps.value ? moment.unix(nextProps.value).format('HH:mm') : ''
            });
        }
    }

    handleOnChangeDateTime() {

        if (this.state.clock) {
            let answer = moment(`${this.state.clock}`, 'HH:mm').unix();
            this.setState({
                value : answer,
                validated: answer ? answer : null,
            });
            this.props.onAnswer({
                answer   : answer,
                validated: answer ? answer : null,
                questionIndex: this.props.question.index
            });
        }

        if (!this.state.clock) {
            this.setState({
                answer : null,
                validated: null,
            });
            this.props.onAnswer({
                answer   : null,
                validated: null,
                questionIndex: this.props.question.index
            });
        }
        this.props.isCurrentQuestion(this.props.question.index);
    }

    moveToNextQuestion() {
        const {question} = this.props;
        let nextToQuestion;
        nextToQuestion = question.nextQuestion ? question.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${nextToQuestion}`);
        this.props.isCurrentQuestion( nextToQuestion + 1);
    }

    render() {
        const {question, disabled,
                  t, isEndQuestion, currentQuestion} = this.props;
        const { clock}        = this.state;
        const contentQuestion = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>
                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className={disabled ? 'number-question bg-disable-question' : 'number-question d-flex justify-content-between'}>
                            <div className="question-number text-overflow-elip">
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div hidden={disabled}>
                                <KirokuButtonLink
                                    className='padding-reset-button-link'
                                    onClick={() => {
                                        this.setState({clock: ''}, () => {
                                            this.handleOnChangeDateTime();
                                        });
                                    }}
                                >
                                    {t('createRecord.resetAnswer')}
                                </KirokuButtonLink>
                            </div>
                        </div>
                        <span className='content-name-question' style={{opacity: disabled ? 0.3 : 1}}>
                            {question.content}
                        </span>
                        <div className='answer-question' style={{opacity: disabled ? 0.3 : 1}}>
                            <div className={'mb-2 mt-2'}/>
                            <KirokuInputIcon name={`${question.index}clock`} icon={'far fa-clock'}
                                             disabled={disabled}
                                             type={'time'}
                                             value={clock}
                                             onChange={e => this.setState({clock: e.target.value},
                                                 this.handleOnChangeDateTime.bind(this))}

                            />
                            <div className={"p-2"} style={{ paddingTop : 10}}
                                 hidden={ disabled || isEndQuestion || question.index !== currentQuestion}>
                                <KirokuButton className="btn-kiroku-primary" onClick={this.moveToNextQuestion.bind(this)}>
                                    {this.props.t('common.nextToQuestion')}
                                </KirokuButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TimeQuestion;
