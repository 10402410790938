import React, { Component }        from 'react';
import {
    KirokuCheckBox,
    KirokuCardWeb,
    KirokuCardTitleWeb,
    KirokuCardBodyWeb, KirokuButton,
    KirokuButtonLink
}                                  from '../../../../ui';
import { withNamespaces }          from 'react-i18next';
import PropTypes                   from 'prop-types';
import { checkUseHeadingQuestion } from '../../../Surveys/SurveyEditor/SurveyService';
import { withSmoothScrolling }     from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
class MultiChoice extends Component {

    state = {
        answers  : this.props.value,
        validated: false,
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.question.index !== nextProps.question.index || this.props.value !== nextProps.value) {
            const value = nextProps.value;
            this.setState({
                answers: value
            });
        }
    }

    handleResetAnswer() {
        let newAnswer  = [...this.state.answers];
        newAnswer = newAnswer.map((answer) => {
            return {...answer, is_choice: false}
        });
        this.setState({answers: newAnswer, validated: false});
        this.props.onAnswer({
            answer       : newAnswer,
            validated    : null,
            questionIndex: this.props.question.index,
        });
        this.props.isCurrentQuestion(this.props.question.index)
    }

    handleOnChange(index, e) {
        const {question} = this.props;
        const newAnswer  = [...this.state.answers];
        newAnswer[index] = {...newAnswer[index], is_choice: e.currentTarget.checked};
        const notAnswer  = newAnswer.filter(answer => !answer['is_choice']).length === question.answers.length;
        this.setState({answers: newAnswer, validated: !notAnswer});
        this.props.onAnswer({
            answer       : newAnswer,
            validated    : !notAnswer ? newAnswer : null,
            questionIndex: this.props.question.index,
        });
        this.props.isCurrentQuestion(this.props.question.index)
    }

    moveToNextQuestion(){
        const { question }           = this.props;
        let nextToQuestion = typeof question.nextQuestion === 'number' ? question.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${nextToQuestion }`);
        this.props.isCurrentQuestion(nextToQuestion + 1);
    }

    render() {
        const { disabled, question, t, isEndQuestion, currentQuestion }   = this.props;
        const { answers } = this.state;
        const contentQuestion = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={disabled ? 'disable-question' :'card-web d-flex justify-content-between'}>
                            <div className='number-question question-number text-overflow-elip'>
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div/>
                            <div hidden={disabled}>
                                <KirokuButtonLink
                                    className='padding-reset-button-link'
                                    onClick={() => {this.handleResetAnswer()}}
                                >
                                    {t('createRecord.resetAnswer')}
                                </KirokuButtonLink>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action cursor-disable' : ''}>
                                <span className='content-name-question'>{question.content}</span>
                                <div className='answer-question'>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            {answers.map((item, index) =>
                                                <KirokuCheckBox key={index}
                                                                checked={item.is_choice}
                                                                id={`${question.index}-${index}`}
                                                                content={item.answer}
                                                                disabled={disabled}
                                                                onChange={disabled ? () => {} : this.handleOnChange.bind(this, index)}
                                                />
                                            )}
                                        </div>
                                        <div hidden={ disabled || isEndQuestion || question.index !== currentQuestion}>
                                            <KirokuButton onClick={this.moveToNextQuestion.bind(this)} className='btn-kiroku-primary'>
                                                {this.props.t('common.nextToQuestion')}
                                            </KirokuButton>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        )
    }
}

MultiChoice.propTypes = {
    value: PropTypes.array
};
export default MultiChoice;
