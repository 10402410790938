import '../../record.css'
import React, {Component}          from 'react';
import {withNamespaces}            from 'react-i18next';
import PropTypes                   from 'prop-types';
import {
    KirokuCardWeb,
    KirokuCardTitleWeb,
    KirokuCardBodyWeb,
    KirokuInput, KirokuButton,
    KirokuButtonLink
}                                  from '../../../../ui';
import { checkUseHeadingQuestion } from "../../../Surveys/SurveyEditor/SurveyService";
import {withSmoothScrolling}       from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
class NumberQuestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value    : props.value || '',
            validated: false,
        }
    }

    componentDidMount() {

    }

    handleChange(event) {
        if (!event) {
            event = { target: {value: ''} }
        }
        let value = event.target.value.replace(/(?!^-)[^0-9.]/g, "").replace(/(\..*)\./g, '$1');
        this.setState({
            value: value,
            validated: !!value,
        });
        this.props.onAnswer({
            answer   : value,
            validated: value ? value : null,
            questionIndex: this.props.question.index
        });
        this.props.isCurrentQuestion(this.props.question.index);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.question.index !== nextProps.question.index || this.props.value !== nextProps.value) {
            this.setState({value: nextProps.value});
        }
    }

    moveToNextQuestion() {
        const {question} = this.props;
        let nextToQuestion = typeof question.nextQuestion === 'number' ? question.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${nextToQuestion }`);
        this.props.isCurrentQuestion(nextToQuestion + 1);
    }

    render() {
        const {
                  question, disabled, t,
                  isEndQuestion, currentQuestion
              } = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3 '>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={disabled ? 'disable-question' :'card-web d-flex justify-content-between'}>
                            <div className='number-question question-number text-overflow-elip'>
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div/>
                            <div hidden={disabled}>
                                <KirokuButtonLink
                                    className='padding-reset-button-link'
                                    onClick={() => {this.handleChange()}}
                                >
                                    {t('createRecord.resetAnswer')}
                                </KirokuButtonLink>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action cursor-disable' : ''}>
                                <span className='content-name-question'>{question.content}</span>
                                <div className='answer-question'>
                                    <KirokuInput disabled={disabled}
                                                 name={`${question.index}-input`}
                                                 onChange={this.handleChange.bind(this)}
                                                 value={this.state.value || ''}
                                                 style={{maxHeight: 90, color : '#495057'}}
                                                 placeholder={t('common.placeholderNumberQuestion')}
                                    />
                                    <div className='pt-2' style={{ float: "right"}} hidden={ disabled || isEndQuestion || question.index !== currentQuestion }>
                                        <KirokuButton className="btn-kiroku-primary" onClick={this.moveToNextQuestion.bind(this)}>
                                            {this.props.t('common.nextToQuestion')}
                                        </KirokuButton>
                                    </div>
                                </div>
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        )
    }
}

NumberQuestion.propTypes = {
    value: PropTypes.string
};
export default NumberQuestion;
