import { DeepDiff } from 'deep-diff';
import _            from 'lodash';
import moment       from 'moment';
const diff = DeepDiff.diff;
const defaultModalViewDifferenceQuestions = {
    isOpenModal: false,
    recordAfter: null,
    recordBefore: null,
    questionDifference: [],
    statusRecordChange: '',
    differences: [],
    servicedAt: ''
};

const isChangeServicedAt = (unixBefore, unixAfter ) => {
    let year = moment.unix(unixBefore.servicedAt).year() !== moment.unix(unixAfter.servicedAt).year();
    let month = moment.unix(unixBefore.servicedAt).month() !== moment.unix(unixAfter.servicedAt).month();
    let day = moment.unix(unixBefore.servicedAt).date() !== moment.unix(unixAfter.servicedAt).date();
    let hh = moment.unix(unixBefore.servicedAt).hour() !== moment.unix(unixAfter.servicedAt).hour();
    let mm = moment.unix(unixBefore.servicedAt).minute() !== moment.unix(unixAfter.servicedAt).minute();
    let has_time = !!unixBefore.has_time !== !!unixAfter.has_time;
    return year || month || day || hh || mm || has_time;
};

const getStatusRecord = (recordBefore, recordAfter) => {
    const statusRecordBefore =  recordBefore['has_draft'] === null ? 'recorded' : 'draft';
    const statusRecordAfter =  recordAfter['has_draft'] === null ? 'recorded' : 'draft';
    return {
        statusRecordBefore,
        statusRecordAfter
    };
};


const ModalViewDifferenceQuestions = (state = defaultModalViewDifferenceQuestions, action) => {
    switch (action.type) {
        case 'IS_OPEN_MODAL_VIEW_DIFFERENCE_QUESTION':
            return {
                ...state,
                isOpenModal: !state.isOpenModal,
                recordAfter: state.isOpenModal ? null : state.recordAfter,
                recordBefore: state.isOpenModal ? null : state.recordBefore,
            };
        case 'GET_CHANGELOG_RECORD':
            let questionsBefore = action.recordBefore.survey_template.questions;
            let questionsAfter = action.recordAfter.survey_template.questions;
            questionsBefore = questionsBefore.map(question => {
                delete question.visibility;
                return question;
            }).filter(question => question.type !== 'Message');
            questionsAfter = questionsAfter.map(question => {
                delete question.visibility;
                return question;
            }).filter(question => question.type !== 'Message');
            let differences = diff(questionsBefore, questionsAfter);
            let isChangeServiceAt = isChangeServicedAt({
                servicedAt: action.recordBefore.serviced_at,
                has_time: action.recordBefore.has_time
            }, {
                servicedAt: action.recordAfter.serviced_at,
                has_time: action.recordAfter.has_time
            });
            return {
                ...state,
                recordBefore: {
                    ...action.recordBefore,
                    survey_template: {
                        ...action.recordBefore.survey_template,
                        questions: questionsBefore
                    }
                },
                recordAfter: {
                    ...action.recordAfter,
                    survey_template: {
                        ...action.recordAfter.survey_template,
                        questions: questionsAfter
                    }
                },
                differences: _.uniqBy(differences, (diff) => {
                    return diff.path[0];
                }).reverse(),
                statusRecordChange: getStatusRecord(action.recordBefore, action.recordAfter),
                servicedAt: {
                    before: {
                        servicedAt: action.recordBefore.serviced_at,
                        has_time: action.recordBefore.has_time,
                    },
                    after: {
                        servicedAt: action.recordAfter.serviced_at,
                        has_time: action.recordAfter.has_time
                    },
                    isChangeServicedAt: isChangeServiceAt
                }
            };
        default: return state;
    }
};
export default ModalViewDifferenceQuestions;
