import React, {Component} from 'react';
import KirokuCardWeb      from "../../../ui/KirokuCardWeb";
import KirokuTable        from "../../../ui/KirokuTabLe";
import THeader            from "../../../ui/THeader";
import TBody              from "../../../ui/TBody";
import {Link}             from "react-router-dom";
import {withNamespaces}   from "react-i18next";
import KirokuAge          from "../../../ui/KirokuAge";
import TabResult          from "../StaffEditor/TabResult";
import {filePathService}  from "../../../services";
import KirokuButton       from "../../../ui/KirokuButton";
import moment             from "moment";

@withNamespaces()
class TabCustomer extends Component {
    handleEditProfile() {
        this.props.history.push('/customers')
    }

    render() {
        let {t, user} = this.props;
        return (
            <div hidden={this.props.show}>
                <KirokuCardWeb>
                    <div className="p-3">
                        {user.customers.length ? <KirokuTable>
                                <THeader>
                                    <tr>
                                        <th>{t('common.Name')}</th>
                                        <th>{t('common.Age')}</th>
                                        <th>{t('CustomerEditor.KindOfCertificate')}</th>
                                        <th className='text-center'/>
                                    </tr>
                                </THeader>
                                <TBody>
                                {user.customers.map(customer => {
                                        let translateCertificate = customer.certificate.map(item => {
                                            return t(`Customer.certificate.${item}`)
                                        });
                                        let certificate          = translateCertificate.join(', ');
                                        return <tr key={customer.id}>
                                            <td><Link
                                                to={`/customers/customer-detail/${customer.id}`}>{customer.name}</Link></td>
                                            <td><KirokuAge dob={moment.unix(customer.date_of_birth)}/></td>
                                            <td>{certificate}</td>
                                            <td className='fix-center'/>
                                        </tr>
                                    }
                                )}
                                </TBody>
                            </KirokuTable> :
                            <div>
                                <TabResult messageFirst={'customer1'} messageSecond={'customer2'}>
                                    <img className='pt-2 pb-2'
                                         src={`${filePathService.resolveFilePath('team-icon@2x.png')}`}
                                         alt="no team"/>
                                </TabResult>
                                <div className='text-center'>
                                    <KirokuButton onClick={this.handleEditProfile.bind(this)} color={'primary'}>
                                        {t('NullPage.customerlist')}
                                    </KirokuButton>
                                </div>
                            </div>}

                    </div>
                </KirokuCardWeb>
            </div>
        )
    }
}

export default TabCustomer;
