import React, { Component }                                                   from 'react';
import moment                                                                 from 'moment';
import PropTypes                                                              from 'prop-types';
import { withNamespaces }                                                     from 'react-i18next';
import Datetime                                                               from 'react-datetime/DateTime';
import { KirokuButton, KirokuCardBodyWeb, KirokuCardTitleWeb, KirokuCardWeb, KirokuButtonLink } from '../../../../ui';
import config                                                                 from '../../../../config';
import { checkUseHeadingQuestion }                                            from '../../../Surveys/SurveyEditor/SurveyService';
import { withSmoothScrolling }                                                from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()

class TimeQuestion extends Component {

    state = {
        value    : null,
        flagEdit : false,
        validated: null,
    };


    componentWillMount() {
        this.setState({
            value: this.props.value ? moment.unix(this.props.value) : null
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.question.index !== nextProps.question.index || this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value ? moment.unix(nextProps.value) : null
            });

        }
    }

    handleOnChangeDateTime(momentTime) {
        if(!momentTime) {
            momentTime = null;
        }
        this.setState({
            value    : momentTime,
            validated: momentTime ?  moment.unix(momentTime) : null,
        });

        this.props.onAnswer({
            answer   : momentTime ? moment(momentTime).unix(): null,
            validated: momentTime ?  moment.unix(momentTime) : null,
            questionIndex: this.props.question.index
        });

        this.props.isCurrentQuestion(this.props.question.index);
    }

    moveToNextQuestion() {
        const {question} = this.props;
        let nextToQuestion = typeof question.nextQuestion === 'number' ? question.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${nextToQuestion}`);
        this.props.isCurrentQuestion(nextToQuestion + 1);
    }

    render() {
        const {
                  disabled, question, t,
                  isEndQuestion, currentQuestion,
              }               = this.props;
        const { value }       = this.state;
        const contentQuestion = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={disabled ? 'disable-question ' :'card-web d-flex justify-content-between'}>
                            <div className='number-question question-number text-overflow-elip'>
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div/>
                            <div hidden={disabled}>
                                <KirokuButtonLink
                                    className='padding-reset-button-link'
                                    onClick={() => {this.handleOnChangeDateTime()}}
                                >
                                    {t('createRecord.resetAnswer')}
                                </KirokuButtonLink>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action cursor-disable' : ''}>
                                <span className='content-name-question'>{question.content}</span>
                                <div className="d-flex">
                                    <div className='answer-question' style={{maxWidth: 250}}>
                                        <Datetime
                                            inputProps={{disabled: disabled}}
                                            value={value}
                                            timeFormat={config.formatTime}
                                            closeOnSelect={true}
                                            dateFormat={false}
                                            onChange={this.handleOnChangeDateTime.bind(this)}
                                            locale={t('createRecord.Date')}
                                        />
                                    </div>

                                    <div style={{ paddingTop: 10}}
                                        hidden={ disabled || isEndQuestion || question.index !== currentQuestion }>
                                        <KirokuButton className="btn-kiroku-primary" onClick={this.moveToNextQuestion.bind(this)}>
                                            {this.props.t('common.nextToQuestion')}
                                        </KirokuButton>
                                    </div>
                                </div>

                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        );
    }
}

TimeQuestion.propTypes = {
    value: PropTypes.number
};
export default TimeQuestion;
