import {
    CHECK_ALL_RECORDS,
    CLEAR_RECORD_TO_EDIT_IN_STORE, CUSTOMER_FOR_TAGS_RECORDS,
    LOAD_DETAIL,
    LOAD_RECORD_TO_EDIT,
    LOAD_RECORDS,
    RECORDS_DASHBOARD,
    RESET_RECORDS,
    SELECTED_RECORD,
    SET_NULL_RECORD, SET_NUMBER_PAGE_LIST_RECORD,
    GET_RECORDS_FOR_DETAIL_PAGE,
    SET_INIT_RECORD_DETAIL_PAGE
} from '../actions/Record';
import { questionsService }       from '../services';
import {
    filterDataRecordToEdit,
    setDisableQuestionNotAnswers,
    setDisableQuestionNotAnswersNotStatus
} from '../services/FitlerData';
import _  from 'lodash';


const defaultApplicationSate = {
    title: 'RECORD',
    surveys: [],
    record: {},
    profile: JSON.parse(localStorage.getItem('profile')),
    records: [{
        id: 1,
        survey_template: {
            description: ''
        },
        customer: {name: ''},
        user: {name: ''}
    }],
    currentPage: 1,
    totalPage: 1,
    recorded: {},
    statedDone: {},
    recordsDashBoard: [],
    recordToEdit: null,
    customerTagged: [],
    loadingCustomer: false,
    loadRecordSuccess: false,
    loadingRecord: [],
    takeRecordPage: false,
    recordToEditBackup: null,
    statusCheck : false,
    recordIds: [],
    indexOfRecordOnRecordIds: 0,
    comments: [],
    hasNext: false,
    recordForDetail: []
};

const checkDisable = (records) => {
    return _.findIndex(records, ['isChecked', true]) !== -1
};


const KirokuAIRecord = (state = defaultApplicationSate, action) => {
    switch (action.type) {
        case 'SET_RECORD_IDS_OF_RECORD_DETAIL':
        case 'SET_RECORDS_BETA':
        case 'LOAD_RECORD_BETA_FOR_BATCH_ACTION':
            return {
                ...state,
                recordIds: action.recordIds,
            };
        case 'HANDLE_SUBMIT_COMMENT_RECORD':
            return  {
                ...state,
                comments: action.comments
            };
        case 'INIT_STATE':
            return {
                ...state,
                loadingCustomer: false
            };
        case "DEFAULT_TAKE_RECORD_PAGE" :
            return {
                ...state,
                takeRecordPage: false
            };
        case 'TAKE_RECORD_PAGE':
            return {
                ...state,
                takeRecordPage: true
            };
        case CUSTOMER_FOR_TAGS_RECORDS:
            return {
                ...state,
                customerTagged: action.customerTagged,
                loadingCustomer: true
            };
        case LOAD_RECORDS :
            return {
                ...state,
                loadingRecord: action.records,
                records: action.records.map(record => ({...record, isChecked: false})),
                defaultRecords: action.records.map(record => ({...record, isChecked: false})),
                currentPage: action.currentPage,
                totalPage: action.totalPage,
                statusCheck: false,
                loadRecordSuccess: true
            };
        case RECORDS_DASHBOARD :
            return {
                ...state,
                records: action.records,
            };
        case LOAD_DETAIL :
            return {
                ...state,
                record: questionsService.getQuestion(action),
                comments: action.record.comment
            };
        case 'LOAD_RECORD_DETAIL_BETA':
            return {
                ...state,
                record: action.record ? questionsService.getQuestion(action) : {},
                recordIds: action.recordIds,
                indexOfRecordOnRecordIds: _.findIndex(action.recordIds,(idRecord) => idRecord === parseInt(action.recordId)),
                comments: action.record.comment
            };
        case CHECK_ALL_RECORDS:
            let newRecords = state.records.map(record => {
                return {
                    ...record,
                    isChecked: !state.statusCheck
                }
            });
            return {
                ...state,
                statusCheck: !state.statusCheck,
                disableButtonApply: checkDisable(newRecords),
                records: newRecords
            };
        case SELECTED_RECORD :
            let recordSelected                     = state.records.map(record => ({...record}));
            recordSelected[action.index].isChecked = !recordSelected[action.index].isChecked;
            return {
                ...state,
                disableButtonApply: checkDisable(recordSelected),
                records: recordSelected,
                statusCheck: _.findIndex(recordSelected, ['isChecked', false]) === -1
            };
        case RESET_RECORDS :
            return {
                ...state,
                statusCheck: false,
                disableButtonApply: checkDisable(state.defaultRecords),
                records: state.defaultRecords.map(record => ({...record}))
            };
        case LOAD_RECORD_TO_EDIT:
            const recordToEdit = filterDataRecordToEdit(action.recordToEdit);
            const survey = recordToEdit['survey_template'];
            const questionsDisableNotAnswers = setDisableQuestionNotAnswers(survey.flowedQuestions, survey.questions);
            const questionsDisableNotAnswersNotDraft = setDisableQuestionNotAnswersNotStatus(survey.flowedQuestions, survey.questions);
            return {
                ...state,
                ...action,
                recordToEdit: {
                    ...recordToEdit,
                    survey_template: {
                        ...recordToEdit.survey_template,
                        questions: questionsDisableNotAnswersNotDraft,
                        flowedQuestions: recordToEdit.survey_template.flowedQuestions.map(questions => {return {...questions, visibility: false}})
                    },
                    statusRecord: recordToEdit['has_draft'] ? {
                        flow: recordToEdit['survey_template'].flowedQuestions.map(question => {return {...question, visibility: false}}),
                        questions: questionsDisableNotAnswers,
                        create_at:recordToEdit.drafted_at
                    }: null
                },
                recordToEditBackup: JSON.parse(JSON.stringify({
                    ...recordToEdit,
                    survey_template: {
                        ...recordToEdit.survey_template,
                        questions: questionsDisableNotAnswersNotDraft,
                        flowedQuestions: recordToEdit.survey_template.flowedQuestions.map(questions => {return {...questions, visibility: false}})
                    },
                    statusRecord: recordToEdit['has_draft'] ? {
                        flow: recordToEdit['survey_template'].flowedQuestions.map(question => {return {...question, visibility: false}}),
                        questions: questionsDisableNotAnswers,
                        create_at:recordToEdit.drafted_at
                    }: null
                }))
            };
        case CLEAR_RECORD_TO_EDIT_IN_STORE:
            return {
                ...state,
                ...action,
                recordToEdit: null,
                recordToEditBackup: null
            };
        case SET_NULL_RECORD:
            return {
                ...state,
                record: {}
            };
        case SET_NUMBER_PAGE_LIST_RECORD:
            return {
                ...state,
                currentPage: action.pageNumber
            };
        case GET_RECORDS_FOR_DETAIL_PAGE :
            return {
                ...state,
                recordForDetail: [...state.recordForDetail, ...action.records],
                hasNext        : action.hasNext,
                currentPage    : action.currentPage,
            }
        case SET_INIT_RECORD_DETAIL_PAGE:
            return {
                ...state,
                recordForDetail: [],
                currentPage: 1,
                hasNext: false
            }
        default :
            return state;
    }


};

export default KirokuAIRecord;
